@import "base";

:root {
  --vh: 100vh;
}

body {
  &.left-cursor:not(.nocursor) {
    cursor: url("/images/recaptcha/arr-left.png"), auto;
  }
  &.right-cursor:not(.nocursor){
    cursor: url("/images/recaptcha/arr-right.png"), auto;
  }
  //@media (max-width: 480px) {
  //  &:not(.body-home-page) {
  //    overflow: hidden;
  //  }
  //}
  & .global-lang-panel {
    & select {
      display: block;
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 700;
      color: #444;
      line-height: 1;
      padding: .4em 1.3em .4em .8em; width: 100%;
      max-width: 100%;
      box-sizing: border-box;
      margin: 0;
      border: 1px solid #aaa;
      box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
      border-radius: .5em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
      background-size: .65em auto, 100%;
      cursor: pointer;
    }
    //position: fixed;
    //right: 20px;
    //top: 20px;
    //z-index: 99999999;
  }
  @media (max-width: 600px) {
    &:not(.body-home-page) {
      & .global-lang-panel {
        display: none;
      }
    }
  }
}



.game-field {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: #000000;
  &.light-theme {
    background: #eaeff1;
  }
}


.home-page  {
  width: 100%;
  min-height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);
  background: #000000;
}

.game-btn {
  width: 237px;
  height: 86px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'NeueMachina-Regular';
  font-size: 26px;
  line-height: 25px;
  cursor: pointer;
  &.start-btn {
    color: #FF1BF7;
    position: relative;
    background: url("/images/game1/default-btn.svg")no-repeat center / cover;
    &.hold-breath-btn {
      width: 330px;
      background: url("/images/hold-breath/btn-border.svg")no-repeat center / cover;
    }
    &.password-game-btn {
      width: 298px;
      color: #ffffff;
      font-size: 24px;
      line-height: 24px;
      @media(max-height: 800px) {
        width: 240px;
        font-size: 20px;
        line-height: 21px;
        height: 67px;
      }
      @media(max-width: 480px) {
        width: 205px;
        height: 58px;
        font-size: 18px;
      }
      @media (max-width: 1600px) and (min-width: 480px) and (max-height: 750px) {
        width: 225px;
        height: 63px;
      }
      @media(max-width: 375px) and (max-height: 700px){
        width: 170px;
        height: 48px;
        font-size: 16px;
      }
      &.success {
        background: url("/images/passwords/success-btn.svg")no-repeat center / cover;
      }
      &.light-btn {
        color: #000000;
        background: url("/images/passwords/light-btn.svg")no-repeat center / cover;
      }
      &.hover-scale {
        @media(min-width: 768px) {
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    @media(max-width: 768px) {

    }
  }
  &.light-btn {
    width: 258px;
    color: #ffffff;
    background: url("/images/recaptcha/light-btn.svg")no-repeat center / cover;
  }
}
.btn-wrapper {
  min-height: 86px;
  display: flex;
  justify-content: center;
  &.absolut-btn-wrapper {
    width: 100%;
    position: absolute;
    &.bottom {
      bottom: 0;
      &.password-game-btn-wrapper {
        @media(max-width: 480px) and (max-height: 820px){
            bottom: 40px;
        }
        @media(max-width: 375px) and (max-height: 700px){
          bottom: 10px !important;
        }
      }
    }
  }
  &.big-btn {
    height: 127px;
    @media (max-width: 375px) {
      height: 80px;
    }
    & .game-btn.start-btn {
      width: 350px;
      height: 127px;
      @media (max-width: 375px) {
        font-size: 20px;
        width: 220px;
        height: 80px;
      }
    }
  }
  & .game-btn.start-btn {
    position: absolute;
    @media (max-width: 768px) and (min-width: 550px){
      &:not(.password-game-btn) {
        transform: scale(1.5) !important;
      }
    }
    @media (max-width: 1024px) and (min-height: 1300px) {
      &:not(.password-game-btn) {
        transform: scale(1.5) !important;
      }
    }
  }
}



.preview-wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 480px) {
    align-items: flex-start;
  }
  & .info-on-main {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Circe-Regular';
    font-size: 20px;
    line-height: 30px;
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    @media (max-width: 480px) {
      max-width: 100%;
    }
    & > div {
      width: 33.3%;
      height: 145px;
      padding: 20px;
      border-bottom: 1px solid #ffffff;
      &:not(:nth-child(3n+3)) {
        border-right: 1px solid #ffffff;
      }
      @media (max-width: 768px) and (min-width: 550px) {
        height: 222px;
      }
      @media (max-width: 1024px) and (min-height: 1300px) {
        height: 222px;
      }
      @media (max-width: 600px) {
        padding: 10px;
      }
      & > div {
        font-family: 'NeueMachina-Regular';
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 5px;
        @media (max-width: 768px) and (min-width: 550px) {
          font-size: 24px;
          line-height: 36px;
        }
        @media (max-width: 1024px) and (min-height: 1300px) {
          font-size: 24px;
          line-height: 36px;
        }
        @media (max-width: 480px) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    & a {
      font-family: 'NeueMachina-Regular';
      display: inline-block;
      font-size: 16px;
      color: #02ff39;
      text-decoration: none;
      cursor: pointer;
      @media (max-width: 768px) and (min-width: 550px) {
        font-size: 24px;
        line-height: 36px;
      }
      @media (max-width: 1024px) and (min-height: 1300px) {
        font-size: 24px;
        line-height: 36px;
      }
      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 24px;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.main-preview {
  width: 685px;
  height: 100%;
  max-height: 708px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("/images/game1/main-bg.png")no-repeat center / cover;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  @media (max-width: 768px) {
    width: 400px;
    height: 46%;
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    max-height: 450px;
  }
  @media (max-width: 400px) {
    max-height: 355px;
  }
}

.main-content {
  width: 668px;
  margin: 0 auto;
  position: relative;
  padding: 96px 0;
  z-index: 2;
  @media (max-width: 768px) and (min-width: 550px) {
    padding: 30px 0;
    padding-bottom: 20px;
  }
  @media (max-width: 1024px) and (min-height: 1300px) {
    padding: 30px 0;
    padding-bottom: 20px;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding: 20px;
  }
  & img {
    width: 225px;
    height: auto;
    margin-bottom: 80px;
    @media (max-width: 768px) and (min-width: 550px) {
      width: 372px;
    }
    @media (max-width: 1024px) and (min-height: 1300px) {
      width: 372px;
    }
    @media (max-width: 600px) {
      margin-bottom: 50px;
    }
  }
}

.game-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 668px;
  min-height: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.back-arrow {
  padding: 30px 0;
  & a img {
    width: 76px;
    height: auto;
    @media (max-width: 480px) {
      height: 26px;
      width: auto;
    }
  }
  @media (max-width: 768px) {
    padding: 20px;
    padding-bottom: 15px;
  }
  &.nav-panel {
    display: flex;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
    @media (max-width: 380px){
      margin-bottom: 10px;
    }
  }
}


.pixel-font {
  font-family: 'PressStart2P-Regular' !important;
}

* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.exponat-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  background: #000000;
  @media (max-width: 750px) {
    padding: 0 20px;
  }
  & .lang-panel {
    display: flex;
    align-items: center;
    & > div {
      font-family: 'NeueMachina-Regular';
      font-size: 20px;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  & .back-arrow {
    padding: 20px 0;
  }
  &.wallpaper-project {
    & .back-arrow {
      width: 100%;
    }
    & .exponat-page-wrapper {
      width: 100%;
      padding: 0 150px;
      @media (max-width: 1200px) {
        padding: 0 50px;
      }
      @media (max-width: 768px) {
        padding: 0;
      }
      & p {
        margin-left: 0;
        &.author {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  & .wallpaper-project-pictures {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 150px;
    margin-top: 60px;
    @media (max-width: 1200px) {
      padding: 0 50px;
    }
    @media (max-width: 768px) {
      padding: 0;
      margin-top: 30px;
    }
    & .wallpaper-item {
      width: calc(50% - 20px);
      margin-bottom: 35px;
      @media (max-width: 600px) {
        width: 100%;
      }
      & a {
        color: #3d7e9a;
        & {
          margin-left: 20px;
        }
      }
      & .author {
        width: auto;
        margin-top: 15px;
        font-size: 20px;
        @media (max-width: 768px) {
          margin-top: 10px;
        }
      }
      & img {
        width: 100%;
        margin-bottom: 15px;
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
      }
    }
  }
  & p.author {
    width: auto;
    font-size: 20px;
  }
  &__title {
    font-family: 'NeueMachina-Regular';
    font-size: 50px;
    line-height: 56px;
    margin-bottom: 20px;
    @media(max-width: 480px) {
      font-size: 41px;
      line-height: 48px;
    }
  }
  & .back-arrow {
    width: 668px;
    @media (max-width: 750px) {
      width: 100%;
    }
  }
  & .video-wrapper {
    margin-top: 30px;
    margin-bottom: 20px;
    @media (max-width: 650px) {
      width: 100%;
      margin-top: 10px;
    }
    & iframe {
      @media (max-width: 650px) {
        width: 100% !important;
        height: auto;
        min-height: 250px;
      }
      @media (max-width: 480px) {
        min-height: 200px;
      }
    }
  }
  & p {
    width: 668px;
    margin: 0 auto;
    font-family: 'NeueMachina-Regular';
    font-size: 18px;
    line-height: 31px;
    margin-top: 30px;
    @media (max-width: 750px) {
      width: 100%;
    }
    @media (max-width: 650px) {
      font-size: 14px;
      line-height: 24px;
      margin-top: 0;
    }
  }
}