.game-navigate-round {
  display: flex;
  flex-direction: column;

  &__modal {
    margin: auto;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__lifes {
    width: 65px;
    height: 33px;
  }

  &__signs {
    max-width: 900px;
    margin: 0 auto;
  }

  &__question {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &__answers {
    width: 100%;

    @media screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }

  &__content {
    position: relative;
    border-radius: 30px;
    background: #FFF;
    max-width: 625px;
    min-height: 401px;
    width: 100%;
    margin-right: 30px;
    padding: 20px 25px;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
      margin-right: 0;
    }
  }

  &__title {
    margin-bottom: 20px;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &__end-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: rgba(0, 0, 0, 0.50);
  }

  &__end {
    border-radius: 10px;
    border: 3px solid #000;
    background: #E8FE87;
    max-width: 318px;
    width: 100%;
    min-height: 197px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 20px 30px;

    @media screen and (max-width: 768px) {
      padding: 15px 15px 20px;
      max-width: 260px;
      min-height: 150px;
    }
  }

  &__end-text {
    text-align: center;
  }

  &__end-button {
    margin-top: auto;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 22px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    &--text {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }

      li {
        color: #000;
      }
    }

    &--end {
      color: #000;
      text-align: center;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}