.conveyor-game-over {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;

  &__body {
    width: 320px;
    border-radius: 10px;
    border: 3px solid #000;
    background: #E8FE87;
    padding: 28px 26px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
      width: 280px;
      padding: 20px 16px 25px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 34px;

    @media screen and (max-width: 768px) {
      font-size: 25px;
    }
  }

  &__font {
    color: #000;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}