.news-label {
  display: flex;
  align-items: center;
  background-color: #1ac23f;
  padding: 3px 12px 3px 9px;
  cursor: pointer;
  user-select: none;

  &__icon {
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 7px;
  }

  &__name {
    text-transform: uppercase;
    text-align: center;
  }

  &__font {
    color: #000;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
  }
}