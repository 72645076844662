.game-navigate-start {
  border-radius: 30px;
  border: 3px solid #000;
  background: #FAF3EA;
  max-width: 625px;
  min-height: 401px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px 25px 40px;

  @media screen and (max-width: 768px) {
    border-radius: 20px;
    padding: 18px 20px 25px;
  }

  &__button {
    margin: auto auto 0;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }

  &__font {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}