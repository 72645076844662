.chat-window {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 30px;
  overflow: hidden;
  border: 2px solid #000;

  @media screen and (max-width: 768px) {
    border-radius: 20px;
  }

  &__chat-wrapper {
    display: flex;
    flex-grow: 1;
  }

  &__chat {
    display: flex;
    flex-direction: column;
    padding: 25px;
    gap: 15px;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      padding: 10px;
      gap: 10px
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__message{
    flex-shrink: 0;
    width: fit-content;
    max-width: 50%;

    @media screen and (max-width: 768px) {
      max-width: 65%;
    }
  }

  &__level {
    width: fit-content;
  }

  &__effect {
    width: fit-content;
    align-self: center;

    &:not(:only-child) {
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
  }

  &__responses {
    width: 100%;
    flex-shrink: 0;
    opacity: 0;
    visibility: hidden;
    translate: 0 100%;
    height: 0;
    transition: translate 0.5s, visibility 0.5s, opacity 0.5s, height 0.5s;
    overflow: hidden;

    &--show {
      visibility: visible;
      translate: none;
      height: auto;
      opacity: 1;
    }
  }

  &__popup-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.50);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}