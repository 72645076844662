@font-face {
  font-family: 'NeueMachina-Light';
  src: local('NeueMachina-Light'), url(/fonts/NeueMachina-Light.otf) format("opentype");
}

@font-face {
  font-family: 'NeueMachina-Regular';
  src: local('NeueMachina-Regular'), url(/fonts/NeueMachina-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Circe-Bold';
  src: local('Circe-Bold'),
  url(/fonts/Circe-Bold.woff) format("woff"),
  url(/fonts/Circe-Bold.ttf) format("truetype");
}

@font-face {
  font-family: 'Caveatregular';
  src: local('Caveatregular'),
  url(/fonts/Caveatregular.woff) format("woff"),
  url(/fonts/Caveatregular.ttf) format("truetype");
}

@font-face {
  font-family: 'Circe-Regular';
  src: local('Circe-Regular'),
  url(/fonts/Circe-Regular.woff) format("woff"),
  url(/fonts/Circe-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'my_phone_n1280', sans-serif;
  src: url(/fonts/my_phone_n1280.ttf) format("truetype");
}

@font-face {
  font-family: 'PressStart2P-Regular';
  /*font-display: fallback;*/
 src: url(/fonts/PressStart2P-Regular.ttf) format("truetype");
}




body {
  margin: 0;
  font-family: 'Circe-Regular',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

input, button {
  outline: none;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div, p, a, span, li {
  color: #fff;
}

pre {
  display: inline-block;
  margin: 0;
}