.signs {
  $parent: &;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 161px;
  row-gap: 60px;

  @media screen and (max-width: 768px) {
    column-gap: 10px;
    row-gap: 40px;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
    }
  }

  &__image-wrapper {
    position: relative;
  }

  &__image {
    object-fit: contain;
    width: 164px;

    @media screen and (max-width: 768px) {
      width: 90px;
    }
  }

  &__icon {
    position: absolute;
    left: 10px;
    bottom: -5px;
    width: 25px;
    height: 25px;

    @media screen and (max-width: 768px) {
      width: 17px;
      height: 17px;
      left: 7px;
      bottom: -2px;
    }
  }

  &__name {
    text-align: center;
  }

  &__font {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  &--size {
    &--medium {
      column-gap: unset;
      row-gap: unset;

      @media screen and (max-width: 768px) {
        column-gap: unset;
        row-gap: unset;
      }

      #{$parent} {
        &__item {
          height: 225px;
        }

        &__image {
          width: 103px;

          @media screen and (max-width: 768px) {
            width: 90px;
          }
        }
      }
    }
  }
}