.license {
  position: relative;
  max-width: 583px;
  width: 100%;
  border-radius: 15px;
  border: 3px solid #000;
  background: #FFF;
  overflow: hidden;


  &__mark {
    width: 125px;
    height: 125px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    @media screen and (max-width: 768px){
      width: 80px;
      height: 80px;
      top: 10%;
      right: 2%;
    }
  }

  &__head {
    border-bottom: 3px solid #000;
    background: #E8FE87;
    padding: 17px 33px;

    @media screen and (max-width: 768px){
      padding: 15px 20px;
    }
  }

  &__body {
    display: flex;
    padding: 15px 33px 58px;
    background-color: white;

    @media screen and (max-width: 768px){
      padding: 10px 20px 30px;
    }
  }

  &__input {
    &--image {
      width: 150px;
      height: 190px;
      margin-right: 32px;

      @media screen and (max-width: 768px){
        width: 100px;
        height: 120px;
        margin-right: 20px;
      }
    }

    &--name, &--surname {
      margin-bottom: 18px;

      @media screen and (max-width: 768px){
        margin-bottom: 10px;
      }
    }

    &--date {
      margin-bottom: 50px;

      @media screen and (max-width: 768px){
        margin-bottom: 25px;
      }
    }
  }

  &__texts {
    display: flex;
    align-items: center;
  }

  &__text {
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 32px;

      @media screen and (max-width: 768px){
        margin-right: 20px;
      }
    }
  }

  &__font {
    &--title {
      color: #000;
      font-size: 27px;
      font-weight: 800;
      line-height: 100%;
      text-transform: uppercase;

      @media screen and (max-width: 768px){
        font-size: 20px;
      }
    }

    &--text {
      color: black;
      font-size: 19px;
      font-weight: 500;
      line-height: 100%;

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
}