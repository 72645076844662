.lifes-header {
  display: flex;
  align-items: center;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    &--right {
      justify-content: flex-end;
    }
  }

  &__points {
    min-width: 61px;
    height: 30px;
  }
}