.license-bundle {
  display: flex;
  flex-direction: column;

  &__button {
    width: 100%;
    margin-top: 20px;

    &--share {
      @media screen and (min-width: 769px) {
        display: none;
      }
    }

    &--download {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}