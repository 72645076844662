.thank-you {
  border: 3px solid #000;
  background: #FFF;
  max-width: 583px;
  width: 100%;
  min-height: 350px;
  padding: 25px 30px 35px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 20px 20px 25px;
    max-width: 335px;
    min-height: 250px;
  }

  &__image {
    width: 160px;
    height: 160px;
    object-fit: contain;
    position: absolute;
    bottom: 25px;
    translate: 20% 100%;
    right: 0;

    @media screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  &__title {
    position: relative;
    margin-bottom: 26px;
  }

  &__text {
    max-width: 80%;
    margin-bottom: 20px;
  }

  &__button {
    margin: auto auto 0;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 50px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 32px;
      }
    }

    &--text {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}