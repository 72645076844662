.circle-progress {
  rotate: -90deg;

  &__bar {
    fill: none;
    stroke: #2E422C;
    stroke-dasharray: 100 100;
    stroke-width: 8px;
    transition: 1s linear;

    &--full {
      stroke: #E8FE87;
    }
  }
}
