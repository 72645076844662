.image-input {
  position: relative;
  display: block;
  background: #D9D9D9;
  cursor: pointer;

  &__field {
    display: none;
  }

  &__empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__icon {
    margin-bottom: 8px;

    @media screen and (max-width: 768px) {
      width: 40px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__font {
    color: rgba(0, 0, 0, 0.50);
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
  }
}