.progress-label {
  &__font {
    color: black;
    font-family: Grotesk, sans-serif;
    font-size: 25px;
    font-style: normal;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}