.message-card {
  border-radius: 10px 10px 10px 0;
  background-color: #F2E6D6;
  align-self: flex-start;
  overflow: hidden;

  &__image {
    width: 100%;
    height: 125px;
    object-fit: contain;
    background-color: #FAF3EA;
  }

  &__link {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: underline;
    padding: 9px 9px 0;
  }

  &__text {
    padding: 9px;
    line-height: 1;
  }

  &__font {
    &--link {
      color: rgba(0, 0, 0, 0.6);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      @media screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 1;
      }
    }

    &--text {
      color: black;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      @media screen and (max-width: 768px) {
        font-size: 15px;
        line-height: 1;
      }
    }
  }

  &--sent {
    border-radius: 10px 10px 0 10px;
    border: 2px solid #F7CDD6;
    background-color: #F7CDD6;
    align-self: flex-end;
  }
}