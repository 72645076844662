.round-modal {
  max-width: 625px;
  min-height: 248px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  border: 3px solid #000;
  background: #F7CDD6;
  padding: 20px 80px 30px;

  @media screen and (max-width: 768px) {
    padding: 20px 30px;
    min-height: 200px;
  }

  &__title {
    text-align: center;
  }

  &__button {
    margin-top: auto;
  }

  &__font {
    color: #2E422C;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 36px;
    }
  }
}