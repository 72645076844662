.reload-button {
  $parent: &;

  border-radius: 69px;
  border: 3px solid #000;
  background: #9D9CF8;
  height: 45px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: black;

    #{$parent} {
      &__font {
        color: #F2E6D6;
      }
    }
  }

  &__font {
    color: black;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    transition: color 0.3s;
  }
}