.points-header {
  display: flex;
  align-items: center;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    @media screen and (max-width: 768px) {
      &--desktop {
        display: none;
      }

      &--center {
        justify-content: flex-start;
      }
    }
  }

  &__points {
    min-width: 75px;
    height: 33px;
  }
}