.game-navigate-explanation {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &__content {
    max-width: 625px;
    min-height: 401px;
    width: 100%;
    border-radius: 30px;
    border: 3px solid #000;
    background: #E8FE87;
    margin-right: 60px;
    padding: 23px 25px 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      margin-top: 20px;
      margin-right: 0;
      min-height: 290px;
    }
  }

  &__button {
    margin: auto auto 0;
  }

  &__font {
    color: #000;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
}