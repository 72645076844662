.timer {
  position: relative;
  width: 65px;
  height: 65px;

  &__circle {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 65px;
    height: 65px;
    z-index: 1;
  }

  &__time {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 2;
  }

  &__font {
    color: #2E422C;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    text-transform: uppercase;
  }
}
