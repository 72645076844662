.colored-button {
  $parent: &;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid black;
  min-width: 224px;
  height: 60px;
  transition: background-color 0.3s;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    min-width: 150px;
  }

  &:hover, &:active {
    background-color: black;

    #{$parent} {
      &__font {
        color: #F2E6D6;
      }
    }
  }

  &:disabled {
    background-color: transparent;
    color: black;
    pointer-events: none;
  }

  &__font {
    color: black;
    font-size: 35px;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    transition: color 0.3s;

    @media screen and (max-width: 768px) {
      font-size: 30px;
    }
  }

  &--theme {
    &--green {
      background-color: #E8FE87;
    }

    &--pink {
      background-color: #F7CDD6;
    }
  }
}