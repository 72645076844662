.text-input {
  display: flex;
  align-items: center;
  border-bottom: 3px solid black;

  &__index {
    min-width: 26px;
    margin-right: 6px;

    @media screen and (max-width: 768px){
      min-width: 15px;
    }
  }

  &__field {
    background-color: transparent;
    border: none;
    max-width: 200px;
    width: 100%;
    height: 26px;
    font-size: 19px;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    padding: 0;

    &::placeholder {
      color: rgba(0, 0, 0, 0.50);
    }
  }

  &__font {
    &--index {
      color: #000;
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
      text-transform: uppercase;

      @media screen and (max-width: 768px){
        font-size: 16px;
      }
    }
  }
}