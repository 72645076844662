.lang-picker {
  $parent: &;

  position: relative;
  z-index: 10;
  border: 3px solid black;

  &__trigger {
    padding: 0 15px 0 21px;
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;

    @media screen and (max-width: 1199px){
      height: 35px;
      padding: 0 10px 0 19px;
    }

    @media screen and (max-width: 768px){
      height: 30px;
      padding: 0 4px 0 9px;
    }
  }

  &__icon {
    width: 18px;
    color: black;

    &--active {
      rotate: 180deg;
    }
  }

  &__options {
    position: absolute;
    z-index: 20;
    top: 100%;
    left: -3px;
    right: -3px;
    display: flex;
    flex-direction: column;
    border: 3px solid black;
    border-top: none;
    background-color: black;
  }

  &__option {
    position: relative;
    margin: 10px 15px 8px 21px;
    padding: 0;
    border: none;
    text-align: left;
    cursor: pointer;
    background-color: transparent;
    text-transform: uppercase;

    @media screen and (max-width: 1199px){
      margin: 12px 10px 12px 19px;
    }

    @media screen and (max-width: 768px){
      margin: 12px 4px 12px 9px;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

    &::after {
      position: absolute;
      top: calc(100% + 2px);
      width: 0;
      height: 3px;
      content: "";
      left: 0;
      background-color: white;
      transition: width 0.3s;
    }
  }

  &__font {
    font-size: 20px;
    line-height: 1;
    color: black;
  }

  &--opened {
    border-color: black;

    #{$parent} {
      &__trigger {
        background-color: black;
      }

      &__icon {
        color: black;
      }

      &__font {
        color: white;
      }
    }
  }
}