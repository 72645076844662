.round-label {
  padding: 4px 10px;
  border-radius: 30px;
  background: #2E422C;

  &__font {
    color: #F7CDD6;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
}