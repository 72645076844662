.chat-responses {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 15px;
  padding: 25px;
  background-color: #F7CDD6;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }

  &__item {
    max-width: 50%;
    border-radius: 10px 10px 0 10px;
    border: 2px solid black;
    text-align: right;
    padding: 5px 9px;
    background-color: transparent;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      max-width: 45%;
    }
  }

  &__font {
    color: black;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 1;
    }
  }
}