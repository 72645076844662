.layout-header {
  $parent: &;

  display: flex;
  align-items: center;
  background-color: #F2E6D6;
  padding: 20px 50px 0;

  @media screen and (max-width: 768px) {
    padding: 17px 13px 0;
  }

  &__logo {
    width: 156px;
    height: 53px;
    margin-right: 23px;

    @media screen and (max-width: 768px) {
      width: 139px;
      height: 48px;
      margin-right: 0;
      margin-top: 5px;
    }
  }

  &__title {
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      max-width: 120px;
    }
  }

  &__wrapper {
    &--logo {
      display: flex;
      align-items: baseline;

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
      }

    }

    &--buttons {
      display: flex;
      align-items: center;
      margin-left: auto;

      @media screen and (max-width: 768px){
        align-self: flex-end;
      }
    }
  }

  &__button {
    margin-right: 53px;

    @media screen and (max-width: 768px){
      display: none;
    }
  }

  &__line {
    width: 100%;
    height: 3px;
    background-color: black;

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &__font {
    &--title {
      font-size: 19px;
      font-weight: 500;
      line-height: 35px;
      color: black;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}