.game-container {
  & .container-subtitle {
    font-family: 'NeueMachina-Regular';
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 20px;
  }
  &.deep-fake {
    @media(max-width: 480px) {
      & .back-arrow.nav-panel {
        margin-bottom: 0 !important;
      }
    }
  }
  &.question-container {
    & .container-content {
     &.deep-fake-content {
       & .video-player {
         width: 100%;
       }
       & img.illustration {
         width: 100%;
       }
       @media (max-width: 480px){
         & .container-content-wrapper {
           height: calc(var(--vh, 1vh) * 100 - 174px);
         }
       }
       @media(max-width: 480px) {
          padding: 0;
         & .info-wrapper {
           padding: 0 20px;
         }
       }
       & .container-title.question-title {
         font-family: 'Circe-Regular';
         margin: 0;
         margin-top: 15px;
         @media(max-width: 600px) {
            font-size: 20px;
           line-height: 28px;
           margin-top: 6px;
         }
       }
       & .question-content {
         color: #b2b2b2;
         margin-bottom: 10px;
         @media(max-width: 600px) {
           font-size: 14px;
           line-height: 22px;
         }
       }
       & .date-text {
         color: #b2b2b2;
         @media(max-width: 600px) {
           font-size: 14px;
           line-height: 22px;
         }
       }
     }
    }
  }
}


.game-container {
  &.deep-fake {
    & .status-popup {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 600px){
        justify-content: flex-start;
        padding: 30px 20px;
        overflow: auto;
      }
      & .video-player {
        width: 600px;
        margin: 0 auto;
        margin-bottom: 20px;
        @media (max-width: 600px){
          width: 100%;
        }
      }
      & .btn-wrapper {
        position: absolute;
        bottom: 0;
        @media (max-width: 600px){
          position: relative;
        }
        & .next-btn {
          color: #000000;
          background: url("/images/game1/black-btn.svg")no-repeat center / cover;
        }
      }
      & > div {
        font-family: 'NeueMachina-Regular';
        font-size: 24px;
        line-height: 30px;
        color: #000000;
      }
      & .popup-animate-element {
        position: relative;
        top: -10%;
        @media (max-width: 600px){
          top: 0;
        }
        & > span {
          display: block;
          color: #000000;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 5px;
        }
        & > p{
          color: #000000;
          margin:0;
          margin-bottom: 12px;
          font-size: 19px;
          line-height: 20px;
          @media (max-width: 600px){
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 10px;
          }
          & > a {
            color: #000000;
          }
        }
        &.video-info {
          display: flex;
          flex-direction: column;
          @media (max-width: 600px){
            margin-bottom: 40px;
          }
        }
        &.animate-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 45px;
          @media (max-width: 600px){
            margin-bottom: 15px;
          }
          & > div {
            color: #000000;
            margin-top: 10px;
          }
        }
      }
      &.fake-status {
        background: #FF3303;
        & .popup-animate-element {
          //&.animate-top {
          //  top: -65px;
          //  animation: animate-top .4s .3s forwards;
          //}
          //&.animate-bottom {
          //  bottom: -430px;
          //  animation: animate-bottom-fake .4s .3s forwards;
          //}
        }
      }
      &.really-status {
        background: #02FF39;
        //& .popup-animate-element {
        //  &.animate-top {
        //    top: -65px;
        //    animation: animate-top .4s .3s forwards;
        //  }
        //  &.animate-bottom {
        //    bottom: -120px;
        //    animation: animate-bottom .4s .3s forwards;
        //  }
        //}
      }
    }
  }
}

//@keyframes animate-top {
//  from {
//    top: -95px;
//  }
//  to {
//    top: calc(50% - 100px);
//  }
//}
//@keyframes animate-bottom {
//  from {
//    bottom: -120px;
//  }
//  to {
//    bottom: calc(50% - 130px);
//  }
//}
//
//@keyframes animate-top-fake {
//  from {
//    top: -95px;
//  }
//  to {
//    top: calc(50% - 100px);
//  }
//}
//
//@keyframes animate-bottom-fake {
//  from {
//    bottom: -430px;
//  }
//  to {
//    bottom: calc(50% - 430px);
//  }
//}

.game-deepfake-question {
  &__container {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }

  &__wrapper {
    max-width: 607px;
    width: 100%;
  }

  &__header {
    margin-bottom: 10px;
  }

  &__body {
    position: relative;
    margin-bottom: 10px;

    &--popup {
      min-height: 340px;
    }
  }

  &__popup-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.50);
    border-radius: 30px;

    &:empty {
      display: none;
    }

    @media screen and (max-width: 768px) {
      border-radius: 15px;
    }
  }

  &__popup {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    text-align: center;
    max-width: 390px;
    width: 100%;
  }

  &__video {
    width: 100%;
    border-radius: 30px;

    @media screen and (max-width: 768px) {
      border-radius: 15px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &__button {
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &__font {
    &--answer {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0em;
      text-align: center;
      color: black;
    }
  }
}

.game-deepfake-result {
  &__thanks {
    margin: auto;
  }

  &__container {
    width: 100%;
    max-width: 607px;
    margin: auto;

    @media screen and (max-width: 768px){
      padding: 0 20px;
    }
  }

  &__content {
    width: 100%;
    min-height: 362px;
    border-radius: 30px;
    border: 3px solid #000;
    background: #9D9CF8;
    display: flex;
    flex-direction: column;
    padding: 34px 27px;

    @media screen and (max-width: 768px){
      border-radius: 20px;
      padding: 20px 15px;
    }
  }

  &__title {
    margin: auto;
  }

  &__button {
    margin: 10px auto 0;
  }

  &__font {
    color: #000;
    text-align: center;
    font-size: 100px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;

    @media screen and (max-width: 768px) {
      font-size: 60px;
    }
  }
}

.game-deepfake-info {
  &__container {
    max-width: 607px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }
  }

  &__wrapper {
    border-radius: 30px;
    border: 3px solid #000;
    background: #FFF;
    padding: 20px 30px 48px;

    @media screen and (max-width: 768px) {
      border-radius: 20px;
      padding: 15px 20px 25px;
    }
  }

  &__title {
    margin-bottom: 17px;

    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  &__button {
    margin: 9px auto 0;
  }

  &__font {
    &--title {
      color: #000;
      font-size: 25px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    &--text {
      color: #000;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

