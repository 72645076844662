.news-card {
  $parent: &;

  position: relative;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 8px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: -11px;
    right: 23px;
    z-index: 3;

    @media screen and (max-width: 768px) {
      gap: 4px;
      right: 10px;
    }
  }

  &__icon {
    width: 25px;
    height: 25px;

    @media screen and (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }

  &__explanation-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;

    &--show {
      opacity: 1;
      visibility: visible;
    }
  }

  &__explanation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border: 3px solid #2E422C;
    background: #2E422C;
    padding: 10px;
    translate: 0 100%;
    transition: translate 0.3s;

    @media screen and (max-width: 768px) {
      padding: 8px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &--show {
      translate: none;
    }
  }

  &__explanation-click {
    margin-top: auto;
    margin-left: auto;
  }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  &__avatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 7px;

    @media screen and (max-width: 768px) {
      width: 44px;
      height: 44px;
    }
  }

  &__text {
    overflow-y: scroll;
    margin-bottom: 5px;
    padding-right: 15px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__buttons {
    position: absolute;
    top: 80px;
    right: 5px;
    display: flex;
    flex-direction: column;
  }

  &__button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__arrow {
    width: 15px;
    color: black;

    &--up {
      rotate: 180deg;
    }
  }

  &__image {
    width: 100%;
    max-height: 160px;
    border-radius: 5px;
    object-fit: cover;
    margin-top: auto;
  }

  &__font {
    &--name {
      color: #000;
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      @media screen and (max-width: 768px) {
        font-size: 17px;
      }
    }

    &--nick {
      color: rgba(0, 0, 0, 0.50);
      font-size: 19px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;

      @media screen and (max-width: 768px) {
        font-size: 17px;
      }
    }

    &--text {
      color: #000;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    &--explanation-title {
      color: #F7CDD6;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      text-transform: uppercase;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }

    &--explanation {
      color: #F7CDD6;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;

      @media screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  &--active {
    border: 3px solid #000;
  }

  &--hidden {
    &::after {
      opacity: 1;
      visibility: visible;
    }

    #{$parent} {
      &__icons {
        display: none;
      }
    }
  }
}