.level-card {
  border-radius: 10px 10px 10px 0;
  border: 2px solid #000;
  background: #E8FE87;
  padding: 9px;

  &__font {
    color: #000;
    font-size: 70px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 40px;
    }
  }
}