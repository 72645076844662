.level-block {
  &__font {
    color: #000;
    text-align: center;
    font-size: 140px;
    font-style: normal;
    font-weight: 800;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 65px;
    }
  }
}