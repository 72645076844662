.prove-result-popup {
  padding: 25px 26px 27px;
  width: 400px;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
    min-height: 250px;
    border-radius: 20px;
  }

  &__icon {
    width: 26px;
    height: 26px;
    margin-bottom: 17px;
  }

  &__text {
    text-align: center;
    margin-bottom: 17px;
  }

  &__button {
    margin-top: auto;
  }

  &__font {
    color: #000;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
      line-height: 1;
    }
  }

  &--color {
    &--green {
      background-color: #E8FE87;
    }

    &--pink {
      background-color: #FAF3EA;
    }
  }
}