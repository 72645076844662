.effect-card {
  border-radius: 10px;
  border: 2px solid #000;
  padding: 5px;

  &__font {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}